import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { createUrlAlias } from "../utils/url"
import _ from "lodash"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"
import styles from "../components/styles"

const style = theme => ({
    ...styles(theme),
})

const CategoriesTemplate = ({ data, classes }) => (
  <Layout>
    <SEO title="Каталог кабеля, провода и электротехнической продукции" />
    <main>
        <h1>Продукция</h1>
        <h2>Кабель и провод</h2>
        <ul className={classes.list}>
          {_.sortBy(data.allMongodbUpcablebaseCables.nodes, obj => obj.name).map(group => (
            <li>
              <Link to={`/catalog/${createUrlAlias(group.name)}/`}>{group.name}</Link>
            </li>
          ))}
        </ul>
    </main>
  </Layout>
)

CategoriesTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(style)(CategoriesTemplate)


export const query = graphql`
    query ClassMarkQuery {
      allMongodbUpcablebaseCables {
        nodes {
          name
        }
      }
    }
  `
